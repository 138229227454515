import { combineReducers } from "redux";
import InsightsListReducer from "./InsightsListReducer";

const rootReducer = combineReducers({
  InsightsListReducer
});

export default rootReducer;



