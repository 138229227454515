import React, { PureComponent } from "react";
import axios, { AxiosResponse } from 'axios';
import Icon from "../components/icon/icon";
import { debounce } from "../../utils/Debounce"
import { SearchHeaderState, SearchFilterType} from "../types/SearchHeaderInterfaces";

class SearchHeaderContainer extends PureComponent<{},SearchHeaderState> {
  constructor(props) {
    super(props);
    this.state = {
      searchHeaderList: [],
      filteredSearchHeaderList: [],
      peopleSearchList: [],
      expertiseSearchList: [],
      insightsSearchList: [],
      visibleSearchList: 3,
      searchTerm: "",
      showSearch: false,
      searchInput: React.createRef()
    }
    this.handleSearchTextChange = this.handleSearchTextChange.bind(this);
    this.handleSearchFilter = this.handleSearchFilter.bind(this);
    this.handleSearchKeypress = this.handleSearchKeypress.bind(this);
    this.handleSearchOpen = this.handleSearchOpen.bind(this);
    this.handleSearchClose = this.handleSearchClose.bind(this);
    this.handleSearchEnterPress = this.handleSearchEnterPress.bind(this);
  }

  componentDidMount(): void {
    const endpointsData = JSON.parse(document.querySelector(`[data-config-id="endpoints"]`).innerHTML);
    axios.get(endpointsData.searchHeader).then((response: AxiosResponse) => {
      this.setState({searchHeaderList : response.data, filteredSearchHeaderList: response.data})
      this.handleSearchFilter(this.state.filteredSearchHeaderList, "People", "peopleSearchList");
      this.handleSearchFilter(this.state.filteredSearchHeaderList, "Service", "expertiseSearchList");
      this.handleSearchFilter(this.state.filteredSearchHeaderList, "Insight", "insightsSearchList");
    })
    .catch(function (error: AxiosResponse) {});
    
  }

  handleSearchTextChange(event) {
    this.setState({searchTerm : event.target.value}, () => {this.handleSearchKeypress()});
  }

  handleSearchFilter(arr, type, state : SearchFilterType) {
    const tempList = [];
    arr.forEach(el => {
      if(el.Type === type) {
        tempList.push(el)
      }
    })
    switch(state) {
      case "peopleSearchList":
        this.setState({ peopleSearchList : tempList })
        break;
      case "expertiseSearchList":
        this.setState({expertiseSearchList : tempList })
        break;
      default:
        this.setState({insightsSearchList : tempList })
    }
  }

  handleSearchKeypress = debounce(function (searchText: string) {
    const { searchTerm, searchHeaderList } = this.state;
    const filteredSearchList = []
    searchHeaderList.forEach((el) => {
      if((el.Title.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) || (el.Subtitle.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1)) {
        filteredSearchList.push(el)
      }
    })
    this.setState({filteredSearchHeaderList : filteredSearchList }, () => {
      this.handleSearchFilter(this.state.filteredSearchHeaderList, "People", "peopleSearchList");
      this.handleSearchFilter(this.state.filteredSearchHeaderList, "Service", "expertiseSearchList");
      this.handleSearchFilter(this.state.filteredSearchHeaderList, "Insight", "insightsSearchList");
    })
  }, 1000);

  handleSearchOpen() {
    const { searchTerm } = this.state
    if(document.body.classList.contains("search-header--active")){
      location.href="/search?searchterm="+searchTerm
    }
    document.body.classList.add("search-header--active");
    this.setState({showSearch: true}, () => {
      this.state.searchInput.current.focus();
    })
  }

  handleSearchClose() {
    document.body.classList.remove("search-header--active");
    this.setState({showSearch: false, searchTerm: ""}, () => { this.handleSearchKeypress(); })
  }

  handleSearchEnterPress(e) {
    const { searchTerm } = this.state
    if(e.key === 'Enter') {
      location.href="/search?searchterm="+searchTerm
    }
  }

  render(): React.ReactNode {
    const { peopleSearchList, expertiseSearchList, insightsSearchList,  searchTerm, visibleSearchList, showSearch, searchInput } = this.state;    

    return (
      <>
        <div className={`header__right__search__form ${showSearch ? 'header__right__search__form--active' : ''}`}>
          <div className="header__right__search__input">
            <input type="text" placeholder="Search" name="headersearch" ref={searchInput} value={searchTerm} onChange={(e) => this.handleSearchTextChange(e)}  onKeyPress={(e) => this.handleSearchEnterPress(e)}  />
          </div>
          <button className="header__right__search__icon" onClick={this.handleSearchOpen}>
            <p className="medium header__right__search__placeholder">Search <span className="medium header__right__search__placeholder__te-reo-text">Rapua</span></p>
            <Icon name="search" />
          </button>
          <button className="header__right__search__close-icon" onClick={this.handleSearchClose}>
            <Icon name="cross" />
          </button>
        </div>
        { showSearch ? <section className="search-header">
          <div className="search-header__wrapper">
            <div className="search-headder__container merw-container">
              <div className="search-header__result">
                <div className="search-header__result__heading">
                  <h5 className="m-0">People</h5>
                  <a href="/people" className="button--label">View all people</a>
                </div>
                <div className="search-header__result__list">                
                  { peopleSearchList.length > 0 ?  peopleSearchList.slice(0, visibleSearchList).map((el, index) => (
                      <a href={el.PageRelativeURL} key={index} className="search-header__result__item text-link">
                        <div className="search-header__result__content">
                        <div className="search-header__result__title">
                          <h6 className="m-0">{el.Title}</h6>
                        </div>
                        <div className="search-header__result__sub-title">
                          <p className="small">{el.Subtitle}</p>
                        </div>
                      </div>
                      <div className="search-header__result__img"
                        style={{backgroundImage: `url(${el.Image})`}}></div>
                    </a>
                    ))
                    : <p className="search-header__result__not-found">No Result found.</p> }
                </div>
              </div>
              <div className="search-header__result">
                <div className="search-header__result__heading">
                  <h5 className="m-0">Expertise</h5>
                  <a href="/expertise" className="button--label">View all Expertise</a>
                </div>
                <div className="search-header__result__list">
									{ expertiseSearchList.length > 0 ?  expertiseSearchList.slice(0, visibleSearchList).map((el, index) => (
										<a href={el.PageRelativeURL} key={index} className="search-header__result__item text-link">
											<div className="search-header__result__content">
												<div className="search-header__result__title">
													<h6 className="m-0">{el.Title}</h6>
												</div>
											</div>
										</a>
									))
									: <p className="search-header__result__not-found">No Result found.</p> }									
                </div>
              </div>
              <div className="search-header__result">
                <div className="search-header__result__heading">
                  <h5 className="m-0">Insights</h5>
                  <a href="/insights" className="button--label">View all insights</a>
                </div>
                <div className="search-header__result__list">
									{ insightsSearchList.length > 0 ?  insightsSearchList.slice(0, visibleSearchList).map((el, index) => (
										<a href={el.PageRelativeURL} key={index} className="search-header__result__item text-link">
											<div className="search-header__result__content">
												<div className="search-header__result__content-type">
													<span className="widecaps">{el.Tag}</span>
												</div>
												<div className="search-header__result__title">
													<h6 className="m-0">{el.Title}</h6>
												</div>
												<div className="search-header__result__date">
													<p className="small">{el.Subtitle}</p>
												</div>
											</div>
											<div className="search-header__result__img search-header__result__img--insights"
												style={{backgroundImage: `url(${el.Image})`}}></div>
										</a>
									))
									: <p className="search-header__result__not-found">No Result found.</p> }
                </div>
              </div>
            </div>
          </div>
        </section> : null}
      </>
    )
  }
}

export default SearchHeaderContainer;
