import React from "react";

export const SampleComponent = () => {
  
  return (
    <h1>Samplee mee</h1>
  )
}

export default SampleComponent;
