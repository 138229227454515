import * as types from './actionTypes';

export const loadHomepageSuccess = () => {
    return {
        type: types.LOAD_SAMPLE
    }
}

export const loadHomepage = () => (dispatch) => {
    dispatch(loadHomepageSuccess())
}
