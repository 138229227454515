import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "../reducers";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__?: typeof compose;
  }
}

let enhancer;
const composeEnhancer =
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();
const middleware = applyMiddleware(thunk);

if (window.__REDUX_DEVTOOLS_EXTENSION__) {
  enhancer = compose(middleware, composeEnhancer);
} else {
  enhancer = compose(middleware);
}

export default function configurationStore() {
  return createStore(rootReducer, enhancer);
}