import React, { PureComponent } from "react";
import axios, { AxiosResponse } from 'axios';

import {PeopleListState, GlobalEndpoints, FilterSelectItem, PeopleListFilterChildItem, FilterDropdownType, PeopleFilterType  } from "../types/PeopleListInterfaces";
import { insertUrlParam, removeUrlParameter } from "../../utils/URLparamsHelper";
import SelectBox from "../components/Form/SelectBox";
import Icon from "../components/icon/icon";

class PeopleListContainer extends PureComponent<{},PeopleListState> {
  constructor(props) {
    super(props);
    this.state = {
      filteredPeopleList: [],
      peopleFiltersList: null,
      peopleList: [],
      visibleListCount: 12,
      filterByService: "",
      filterBySector: "",
      filterByLocation: "",
      servicesDropdown: [],
      sectorDropdown: [],
      locationDropdown: [],
      searchTerm: "",
      peopleVisible:false
    };
    this.handleLoadMoreClick = this.handleLoadMoreClick.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleResetFilterCLick = this.handleResetFilterCLick.bind(this);
    this.handleSearchTextChange = this.handleSearchTextChange.bind(this);
    this.handleSearchBtnClick = this.handleSearchBtnClick.bind(this);
  }

  componentDidMount(): void {
    const endpointsData: GlobalEndpoints = JSON.parse(document.querySelector(`[data-config-id="endpoints"]`).innerHTML);

    axios.get(endpointsData.peopleList).then((insightsListsresponse: AxiosResponse) => {
      axios.get(endpointsData.peopleListFilter).then((insightsListsFilterresponse: AxiosResponse) => {
        this.setState({ peopleList: insightsListsresponse.data, peopleFiltersList : insightsListsFilterresponse.data, peopleVisible:true }, () => {this.handleInsightsListResponse()})
      })
    })
    .catch(function (error: AxiosResponse) {});
  }

  handleInsightsListResponse() {
    
    const { peopleFiltersList } = this.state;
    
    if(peopleFiltersList){
      const { Services, Sectors, Locations } = peopleFiltersList;
      // Grab query parameters
      const urlSearchParams: any = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      
      const serviceQuery = params.AllServices ? `${this.getFilterCodeNumber(params.AllServices, Services)};${params.AllServices}` : ''
      const sectorQuery = params.AllSector ? `${this.getFilterCodeNumber(params.AllSector, Sectors)};${params.AllSector}` : ''
      const loactionQuery = params.AllLocation ? `${this.getFilterCodeNumber(params.AllLocation, Locations)};${params.AllLocation}` : '';
      const searchQuery = params.Search ? params.Search : '';
      
      this.setState({
        filterByService: serviceQuery, 
        filterBySector: sectorQuery,
        filterByLocation: loactionQuery,
        searchTerm : searchQuery
      }, () => { 
        this.modalSelectDropdownData(Services, "servicesDropdown") 
        this.modalSelectDropdownData(Sectors, "sectorDropdown") 
        this.modalSelectDropdownData(Locations, "locationDropdown")
        this.handleInsightsFilterChange()
      })
    }
  }

  modalSelectDropdownData(filterDropdown: Array<PeopleListFilterChildItem>, type: FilterDropdownType) {
    const result: Array<FilterSelectItem> = [];
    filterDropdown.forEach((el) => {
      result.push({
        value: `${el.NodeGUID};${el.NodeName}`,
        name: el.Title
      })
    })
    switch(type) {
      case "servicesDropdown":
        this.setState({ servicesDropdown : result })
        break;
      case "sectorDropdown":
        this.setState({ sectorDropdown : result })
        break;
      default:
        this.setState({ locationDropdown : result })
    }
  }

  getFilterCodeNumber(value: string, insightsFilter: Array<PeopleListFilterChildItem>) {    
    let result = ""
    insightsFilter.forEach((el) => {
      if(el.NodeName === value) {
        result = el.NodeGUID
      }
    })
    return result;
  }

  handleLoadMoreClick() {
    this.setState({visibleListCount: this.state.visibleListCount * 2})
  }

  handleSelectChange(value: string, name: PeopleFilterType) {
    // On select change, append the value to URL
    switch (name) {
      case "All Services":
        this.setState({ filterByService: value}, () => {this.handleQueryParamsChange(value,name)});
        break;
        case "All Sectors":
          this.setState({ filterBySector: value}, () => {this.handleQueryParamsChange(value,name)});
        break;
        default:
        this.setState({ filterByLocation: value}, () => {this.handleQueryParamsChange(value,name)});
    }
  }

  handleQueryParamsChange(paramsValue: string, paramsName: PeopleFilterType) {
    const queryValue = paramsValue.split(";")[1] ? paramsValue.split(";")[1] : '';
    
    if(queryValue) {
      insertUrlParam(paramsName.replace(/ /g,''), queryValue)
    } else {
      removeUrlParameter(paramsName.replace(/ /g,''));
    }
    this.handleInsightsFilterChange()
  }

  handleInsightsFilterChange() {
    const { peopleList, filterByService, filterBySector, filterByLocation, searchTerm  } = this.state;
    const updatedFilterList = [];
    peopleList.forEach((element,index) => {
      if(this.insightFilterCondition(filterByService.split(";")[0], element.Services) && this.insightFilterCondition(filterBySector.split(";")[0], element.Sectors) && this.insightFilterCondition(filterByLocation.split(";")[0], element.Location) && (this.insightFilterCondition(searchTerm.toLowerCase(), element.FullName.toLowerCase()) || this.insightFilterCondition(searchTerm.toLowerCase(), element.JobTitle.toLowerCase()) || this.insightFilterCondition(searchTerm.toLowerCase(), element.PrimaryPracticeArea.toLowerCase())) ) {
        updatedFilterList.push(element)
      }
    });
    this.setState({ filteredPeopleList : updatedFilterList });
  }

  handleResetFilterCLick() {
    this.setState({
      filterByService: "", 
      filterBySector: "",
      filterByLocation: "",
    }, () => { this.handleInsightsFilterChange()})
    removeUrlParameter("All Services")
    removeUrlParameter("All Sectors")
    removeUrlParameter("All Locations")
  }

  insightFilterCondition(data,element) {
    return data === "" ? true :  element.indexOf(data) === -1  ? false : true
  }

  handleSearchTextChange(event) {
    this.setState({searchTerm : event.target.value})
  }

  handleSearchBtnClick() {
    const { searchTerm } = this.state;
    if(searchTerm) {
      insertUrlParam("Search", searchTerm)
    } else {
      removeUrlParameter("Search");
    }
    this.handleInsightsFilterChange()
  }

  handleSearchEnterPress(event) {
    if(event.key === 'Enter'){
      this.handleSearchBtnClick();
    }
  }

  render(): React.ReactNode {
    const { filteredPeopleList, 
      visibleListCount,
      servicesDropdown, 
      sectorDropdown, 
      locationDropdown, 
      filterByService,
      filterBySector,
      filterByLocation,
      searchTerm,
      peopleVisible
    } = this.state;    

    return (
      <div className="people-list">
      <div className="people-list__wrapper">
         <div className="people-list__container merw-container">
            <div className="people-list__search">
               <div className="people-list__search__wrapper">
                  <div className="people-list__search__container">
                     <h5 className="people-list__search__label m-0">Find a person</h5>
                     <div className="people-list__search__input">
                       <Icon className="people-list__search__icon__desktop" name="search"/>
                       <input type="text" placeholder="Find a person" value={searchTerm} name="searchTerm" onChange={this.handleSearchTextChange} onKeyPress={e=>this.handleSearchEnterPress(e)}/>
                       <button className="button button--primary button--label" onClick={this.handleSearchBtnClick}>
                          <span>
                            <Icon className="people-list__search__icon__mobile" name="search"/>
                          </span>
                          <span className="people-list__search__icon__text">Search</span>
                       </button>
                     </div>
                  </div>
               </div>
            </div>
            
           <div className="people-list__filter">
              <div className="people-list__filter__wrapper">
                 <div className="people-list__filter__accordion accordion">
                    <div className="people-list__filter__accordion__header">
                       <h5 className="m-0">
                        Filter people
                       </h5>
                       <button className="people-list__filter__accordion__button accordion__button">
                          <Icon name="filter"/>
                       </button>
                    </div>
                    <div className="people-list__filter__dropdown accordion__content">
                       <p>Filter by: </p>
                       <SelectBox placeholder="All Services" selectedValue={filterByService} options={servicesDropdown} handleOnChange={this.handleSelectChange} />
                       <SelectBox placeholder="All Sectors" selectedValue={filterBySector} options={sectorDropdown} handleOnChange={this.handleSelectChange} />
                       <SelectBox placeholder="All Locations" selectedValue={filterByLocation} options={locationDropdown} handleOnChange={this.handleSelectChange} />
                    <button className="button button--secondary button--secondary--grey accordion__button--close">
                       Close
                     </button>
                     <button className="button button--secondary button--secondary--grey" onClick={this.handleResetFilterCLick}>
                       <span className="people-list__filter__dropdown__reset__text">Reset filters</span>
                       <Icon name="restart"/>
                     </button>
                    </div>
                 </div>
              </div>
            </div>
              {!peopleVisible && <div className="loader">
              <svg
                  className="loading-icon"
                  viewBox="0 0 15 15"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  focusable="false"
                >
                <use xlinkHref="/public/assets/icons/spritemap.svg#loader"></use>
                </svg>
                </div>}
           {peopleVisible && <div className="people-list__cards">
               <div className="people-list__cards__wrapper">
               { filteredPeopleList?.length > 0 ? filteredPeopleList.slice(0, visibleListCount).map((el, index) => (
                 <div className="component contacts-card-component fade-in" key={index}>
                 <div className="component-content">
                   <section aria-label="Contact Card" className="contacts-card contacts-card--small contacts-card--small--v2 contacts-card--grey--body">
                     <div className="contacts-card__container">
                       <div className="contacts-card__wrapper">
                         <a href={el.PageRelativeURL} className="contacts-card__figure">
                           <img src={el.TileImage} alt={el.FullName} />
                         </a>
                         <div className="contacts-card__body">
                           <a href={el.PageRelativeURL} className="contacts-card__person-name">
                             <h4 className="m-0">{el.FullName}</h4>
                           </a>
                           <h5 className="contacts-card__position bold">{el.JobTitle}</h5>
                           <p className="contacts-card__practise-area">{el.PrimaryPracticeArea}</p>
                           <div className="contacts-card__icons">
                            {
                              el.BusinessPhone && 
                                <a href={`tel:${el.BusinessPhone}`} className="contacts-card__icons__phone">
                                  <Icon name="phone"/>
                                </a>
                             }
                            {
                              el.Email && 
                                <a href={`mailto:${el.Email}`} className="contacts-card__icons__email">
                                  <Icon name="email"/>
                                </a>
                             }
                             {
                              el.LinkedIn && 
                                <a href={el.LinkedIn} className="contacts-card__icons__linkdin">
                                  <Icon name="linkedIn"/>
                                </a>
                             }
                           </div>
                         </div>
                       </div>
                     </div>
                   </section>
                 </div>
               </div>
               )) : <h3>No results</h3> }
               {visibleListCount < filteredPeopleList.length && (
                  <div className="search-list__load-more-btn">
                    <button
                      onClick={this.handleLoadMoreClick}
                      className="button button--secondary button--secondary--half-width-animation m-auto"
                    >
                      Load more
                      <Icon name="arrow-down"/>
                    </button>
                  </div>
                )}
               </div>
            </div>}
         </div>
      </div>
   </div>
    );
  }
}

export default PeopleListContainer;
