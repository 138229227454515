import React, { useState } from "react";
import propTypes from "prop-types";
import Icon from "../../components/icon/icon";

interface OptionsInterface {
  name: string;
  value: string;
}

const SelectBox = (props) => {
  const { label, options, handleOnChange, selectedValue, placeholder } = props;

  const [inputvalue, setValue] = useState<string>("");

  const renderOptions = (optionsArray: Array<OptionsInterface>) => {
    const options = [];
    optionsArray.map((item, i) => {
      options.push(<option key={i} value={item.value}> {item.name} </option>);
    });
    return options;
  };

  const handleSelectChange = (evt: React.ChangeEvent<HTMLSelectElement>) => {
    setValue(evt.target.value);
    handleOnChange(evt.target.value, placeholder);
  }

  return (
    <div className="input-form" >
        <div className="input-form__field">
          <select
            className="input-form__field__select"
            onChange={(evt) => handleSelectChange(evt)}
            value={selectedValue}
          >
            <option value=""> { placeholder } </option>
            {renderOptions(options)}
          </select>
          <Icon name="dropdown-arrow"/>
          { label && <label className="input-form__field__label">{label}</label>}
        </div>
    </div>
  );
};

SelectBox.defaultProps = {
  label: null,
  options: [],
  handleOnChange: (()=>{}),
  selectedValue: "",
  placeholder: ""
};

SelectBox.propTypes = {
  label: propTypes.string,
  options: propTypes.array,
  handleOnChange: propTypes.func,
  selectedValue: propTypes.string,
  placeholder: propTypes.string
};

export default SelectBox;