import React from 'react';

interface IconProps {
  className?: string;
  name: string;
}

const Icon: React.FunctionComponent<IconProps> = (props) => {
  const baseClass = 'icon';
  return (
    <svg
      viewBox="0 0 45 45"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http:://www.w3.org/1999/xlink"
      focusable="false"
      aria-hidden="true"
      className={baseClass + (props.className ? ' ' + props.className : '')}
    >
      <use href={`/public/assets/icons/spritemap.svg#${props.name}`} />
    </svg>
  );
};

export default Icon;
