import React, { PureComponent } from "react";
import axios, { AxiosResponse } from 'axios';

import {InsightsListState, GlobalEndpoints, InsightsFilterType  } from "../types/InsightsListInterfaces";
import { insertUrlParam, removeUrlParameter } from "../../utils/URLparamsHelper";
import SelectBox from "../components/Form/SelectBoxv2";
import Icon from "../components/icon/icon";

class InsightsListContainer extends PureComponent<{},InsightsListState> {
  constructor(props) {
    super(props);
    this.state = {
      insightsList: [],
      totalListCount: 0,
      filterByService: "",
      filterBySector: "",
      filterByTopics: "",
      filterByInsightsType: "",
      servicesDropdown: [],
      sectorDropdown: [],
      topicsDropdown: [],
      contentTypeDropdown: [],
      insightsVisible:false,
      batchNumber: 1,
      loadingMoreLists: false
    };
    this.handleLoadMoreClick = this.handleLoadMoreClick.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleResetFilterCLick = this.handleResetFilterCLick.bind(this);
  }

  componentDidMount(): void {
    const endpointsData: GlobalEndpoints = JSON.parse(document.querySelector(`[data-config-id="endpoints"]`).innerHTML);

    const url = window.location.href;
    const urlObj = new URL(url);

    let urlParams = [];

    const searchParams = new URLSearchParams(urlObj.search);
    
    for (const [key, value] of searchParams.entries()) {
      urlParams.push(`${key}=${value}`);
    }
    
    const extractedParams = {
      service: '',
      sector: '',
      topic: '',
      contentType: ''
    };

    for (const [key, value] of searchParams.entries()) {
      if (key === 'service' || key === 'sector' || key === 'topic' || key === 'contentType') {
        extractedParams[key] = value;
      }
    }

    axios.get(endpointsData.insightsList+`&batchNumber=1&${urlParams.join("&")}`).then((insightsListsresponse: AxiosResponse) => {
      axios.get(endpointsData.insightsListFilter).then((insightsListsFilterresponse: AxiosResponse) => {
        let totalCount: 0;
        const { Services, Topics, Sectors, ContentTypes } = insightsListsFilterresponse.data;

        if(insightsListsresponse.data.length) {
          totalCount = insightsListsresponse.data[0].TotalCount
        }

        this.setState({ 
          insightsList: insightsListsresponse.data,
          servicesDropdown: Services,
          topicsDropdown: Topics,
          sectorDropdown: Sectors,
          contentTypeDropdown: ContentTypes,
          insightsVisible:true,
          totalListCount: totalCount,
          filterByService: extractedParams.service,
          filterBySector: extractedParams.sector,
          filterByTopics: extractedParams.topic,
          filterByInsightsType: extractedParams.contentType,
        })
      })
    }).catch(function (error: AxiosResponse) {});
  }

  handleLoadMoreClick(resetBatchNumber: boolean) {
    let { batchNumber, filterByService, filterBySector, filterByTopics, filterByInsightsType } = this.state;
    const endpointsData: GlobalEndpoints = JSON.parse(document.querySelector(`[data-config-id="endpoints"]`).innerHTML);
    
    const urlParams = [];
    const updatedBatchNumber = resetBatchNumber ? 1 : ++batchNumber;

    const params = {
      batchNumber: updatedBatchNumber,
      service: filterByService,
      sector: filterBySector,
      topic: filterByTopics,
      contentType: filterByInsightsType,
    };

    for (const [key, value] of Object.entries(params)) {
      if (value) {
        urlParams.push(`${key}=${value}`);
      }
    }
    
    if(resetBatchNumber) {
      this.setState({insightsVisible: false})
    } else {
      this.setState({loadingMoreLists: true});
    }

    axios.get(endpointsData.insightsList+`&${urlParams.join("&")}`).then((insightsListsresponse: AxiosResponse) => {

      let totalCount: 0;

      if(insightsListsresponse.data.length) {
        totalCount = insightsListsresponse.data[0].TotalCount
      }

        this.setState({
          batchNumber: updatedBatchNumber,
          totalListCount: totalCount,
          insightsList: resetBatchNumber? [...insightsListsresponse.data] : [...this.state.insightsList, ...insightsListsresponse.data],
          insightsVisible: true,
          loadingMoreLists: false
        }, () => this.handleQueryParamsChange());
    })
  }

  handleSelectChange(value: string, name: InsightsFilterType) {
    
    // On select change, append the value to URL
    switch (name) {
      case "All Services":
        this.setState({ filterByService: value}, () => { this.handleLoadMoreClick(true)});
        break;
        case "All Sectors":
          this.setState({ filterBySector: value}, () => {this.handleLoadMoreClick(true)});
        break;
        case "All Topics":
          this.setState({ filterByTopics: value}, () => {this.handleLoadMoreClick(true)});
        break;
      default:
        this.setState({ filterByInsightsType: value}, () => {this.handleLoadMoreClick(true)});
    }
  }

  handleQueryParamsChange() {
    const { batchNumber, filterByService, filterBySector, filterByTopics, filterByInsightsType } = this.state;

    const params = {
      service: filterByService,
      sector: filterBySector,
      topic: filterByTopics,
      contentType: filterByInsightsType,
    };

    for (const [key, value] of Object.entries(params)) {
      if (value) {
        insertUrlParam(key, value);
      } else {
        removeUrlParameter(key);
      }
    }

  }

  handleResetFilterCLick() {
    this.setState({
      filterByService: "", 
      filterBySector: "",
      filterByTopics: "",
      filterByInsightsType : ""
    }, () => { this.handleLoadMoreClick(true)})
  }

  render(): React.ReactNode {
    
    const { 
      totalListCount,
      servicesDropdown, 
      sectorDropdown, 
      topicsDropdown, 
      contentTypeDropdown,
      filterByService,
      filterBySector,
      filterByTopics,
      filterByInsightsType,
      insightsVisible,
      insightsList,
      loadingMoreLists
    } = this.state;
    
    return (
      <div className="search-list">
        <div className="search-list__wrapper">
          <div className="search-list__container merw-container">
            <div className="search-list__filter">
              <div className="search-list__filter__wrapper">
                <div className="search-list__filter__accordion accordion">
                  <div className="search-list__filter__accordion__header">
                    <h5 className="m-0">Filter insights</h5>
                    <button className="search-list__filter__accordion__button accordion__button">
                      <Icon name="filter"/>
                    </button>
                  </div>
                  <div className="search-list__filter__dropdown accordion__content">
                    <p>Filter by: </p>
                    <SelectBox selectedValue={filterByService} options={servicesDropdown} placeholder="All Services" handleOnChange={this.handleSelectChange} />
                    <SelectBox selectedValue={filterBySector} options={sectorDropdown} placeholder="All Sectors" handleOnChange={this.handleSelectChange} />
                    <SelectBox selectedValue={filterByTopics} options={topicsDropdown} placeholder="All Topics" handleOnChange={this.handleSelectChange} />
                    <SelectBox selectedValue={filterByInsightsType} options={contentTypeDropdown} placeholder="All Insight Types" handleOnChange={this.handleSelectChange} />
                    <button className="button button--secondary button--secondary--grey accordion__button--close">
                      Close
                    </button>
                    <button className="button button--secondary button--secondary--grey" onClick={this.handleResetFilterCLick}>
                      Reset filters
                      <Icon name="restart"/>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="search-list__cards">
            {!insightsVisible && <div className="loader">
            <svg
                className="loading-icon"
                viewBox="0 0 15 15"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                focusable="false"
              >
              <use xlinkHref="/public/assets/icons/spritemap.svg#loader"></use>
              </svg>
              </div>}
              {insightsVisible && <div className="search-list__cards__wrapper">
                { insightsList?.length > 0 ? insightsList.map((el) => {
                  if(el.ContentTypeTitle === "Podcast") {
                    return (
                      <div className="component article-card-component fade-in" key={el.NodeGUID}>
                        <div className="component-content">
                          <a
                            href={el.PageRelativeURL}
                            aria-label="Article Card"
                            className="article-card article-card--bg article-card--bg--small article-card--podcast"
                          >
                            <div className="article-card__container">
                              <div className="article-card__wrapper">
                                {el.MobileImage && <div className="article-card__bg" style={{backgroundImage: `url(${el.MobileImage})`}}>
                                    <img className="article-card__podcast-icon" src="/public/assets/images/podcast-icon.png" alt="podcast icon image" />
                                </div>}
                                <div className="article-card__body">
                                  <p className="article-card__type">{el.ContentTypeTitle}</p>
                                  <h5 className="article-card__heading">
                                    {el.Title}
                                  </h5>
                                  <p className="article-card__date small">
                                    {el.PublishedOn}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    )
                  } else if(el.MobileImage !== "") {
                    return (
                      <div className="component article-card-component fade-in" key={el.NodeGUID}>
                        <div className="component-content">
                          <a
                            href={el.PageRelativeURL}
                            aria-label="Article Card"
                            className="article-card article-card--bg article-card--bg--small"
                          >
                            <div className="article-card__container">
                              <div className="article-card__wrapper">
                                <div
                                  className="article-card__bg"
                                  style={{backgroundImage: `url(${el.MobileImage})`}}
                                >
                                </div>
                                <div className="article-card__body">
                                  <p className="article-card__type">{el.ContentTypeTitle}</p>
                                  <h5 className="article-card__heading">
                                    {el.Title}
                                  </h5>
                                  <p className="article-card__date small">
                                    {el.PublishedOn}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    )
                  } else {
                    return ( 
                      <div className="component article-card-component" key={el.NodeGUID}>
                        <div className="component-content">
                          <a href={el.PageRelativeURL} aria-label="Article Card" className="article-card">
                            <div className="article-card__container">
                              <div className="article-card__wrapper">
                                <div className="article-card__body">
                                  <p className="article-card__type">{el.ContentTypeTitle}</p>
                                  <h5 className="article-card__heading">{el.Title}</h5>
                                    <p className="article-card__date small">{el.PublishedOn}</p>
                                  <div className="article-card__icon">
                                    <Icon name="upward-right-lg"/>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    )
                  }
                }) : <h3>No results</h3> }
                {insightsList.length < totalListCount && (
                  <div className="search-list__load-more-btn">
                    <button
                      onClick={() => this.handleLoadMoreClick(false)}
                      className="button button--secondary button--secondary--half-width-animation m-auto"
                    >
                     {loadingMoreLists ? 'Loading more' : 'Load more'}
                      <Icon name="arrow-down"/>
                    </button>
                  </div>
                )}
              </div>}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default InsightsListContainer;
