import * as types from '../actions/actionTypes';

const initialState = { };

export default function InsightsListReducer(state = initialState, action) {
  switch (action.type) {
    case types.LOAD_INSIGHTS_LIST:
      return {
        ...state,
        insightsList: action.insightsListObj.insightsList,
        insightsListFilter: action.insightsListObj.insightsListFilter
      };
    default:
      return state;
  }
}
