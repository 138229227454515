import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as actions from "../actions/sampleActions";
import SampleComponent from "../components/sampleComponent";

class SampleContainer extends PureComponent {
  
  render(): React.ReactNode {    
      return <SampleComponent />
  }
}

const mapStateToProps = (state) => {
  const {
    loading 
  } = state;
  return { loading };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SampleContainer);