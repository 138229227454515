import React from "react";
import { Provider } from "react-redux";

import { store } from "../entries";
import SampleContainer from "../containers/SampleContainer";

export default (props) => {
  return (
    <Provider store={store}>
      <SampleContainer />
    </Provider>
  );
} 
