import React from "react";
import { Provider } from "react-redux";

import { store } from "../entries";
import SearchHeaderContainer from "../containers/SearchHeaderContainer";

export default (props) => {
  return (
    <Provider store={store}>
      <SearchHeaderContainer />
    </Provider>
  );
}
