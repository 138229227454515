import React from "react";
import { Provider } from "react-redux";

import { store } from "../entries";
import SearchContainer from "../containers/SearchContainer";

export default (props) => {
  return (
    <Provider store={store}>
      <SearchContainer />
    </Provider>
  );
}
