import React from "react";
import { Provider } from "react-redux";

import { store } from "../entries";
import InsightsListContainer from "../containers/InsightsListContainer";

export default (props) => {
  return (
    <Provider store={store}>
      <InsightsListContainer />
    </Provider>
  );
} 
