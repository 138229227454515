import configurationStore from "../stores/main";
import ComponentToElementMap from "../../utils/ComponentToElementMap";
import SampleProvider from "../providers/SampleProvider";
import InsightsListProvider from "../providers/InsightsListProvider";
import PeopleListProvider from "../providers/PeopleListProvider";
import SearchHeaderProvider from "../providers/SearchHeaderProvider";
import SearchProvider from "../providers/SearchProvider";

const map = [
  { nodeSelector: ".js-mw-sample", component: SampleProvider },
  { nodeSelector: ".js-insight-list", component: InsightsListProvider },
  { nodeSelector: ".js-people-list", component: PeopleListProvider },
  { nodeSelector: ".js-search-header", component: SearchHeaderProvider },
  { nodeSelector: ".js-search-results", component: SearchProvider },
];

export const store = configurationStore();

new ComponentToElementMap(map);