import React from "react";
import { Provider } from "react-redux";

import { store } from "../entries";
import PeopleListContainer from "../containers/PeopleListContainer";

export default (props) => {
  return (
    <Provider store={store}>
      <PeopleListContainer />
    </Provider>
  );
}
