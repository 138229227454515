import React, { Suspense, lazy, ComponentType } from "react";
import ReactDOM from "react-dom";

type LazyLoadedComponent = () => Promise<{ default: ComponentType<any> }>;
type EagerLoadedComponent = ComponentType<any>;

export type RenderTarget =
  | {
      nodeSelector: string;
      lazyComponent: LazyLoadedComponent;
    }
  | {
      nodeSelector: string;
      component: EagerLoadedComponent;
    };

export default class ComponentToElementMap {
  targets: RenderTarget[];
  constructor(targets: RenderTarget[]) {
    if (Boolean(targets.length)) {      
      this.targets = targets;
      this.init();
    }
  }

  private init() {
    this.targets.map((target) => {
      const nodeElements = document.querySelectorAll(target.nodeSelector);
      if (nodeElements.length) {
        "lazyComponent" in target
          ? this.renderLazyComponent(target.lazyComponent, nodeElements)
          : this.renderEagerComponent(target.component, nodeElements);
      }
    });
  }

  private renderLazyComponent(
    lazyComponent: LazyLoadedComponent,
    nodeElements: NodeListOf<Element>
  ) {
    const LazyComponent = lazy(lazyComponent);
    nodeElements.forEach((node) => {
      const options = node.attributes["data-options"];
      ReactDOM.render(
        <Suspense fallback={null}>
          <LazyComponent options={options && JSON.parse(options.value)} />
        </Suspense>,
        node
      );
    });
  }

  private renderEagerComponent(
    component: EagerLoadedComponent,
    nodeElements: NodeListOf<Element>
  ) {
    const Component = component;
    nodeElements.forEach((node) => {
      const options = node.attributes["data-options"];
      ReactDOM.render(
        <Component options={options && JSON.parse(options.value)} />,
        node
      );
    });
  }
}
